<template>
  <b-modal
      id="bv-modal-post-help"
      size="lg"
      hide-footer
  >
    <template #modal-title>
      Speciális jelölések a szövegben
    </template>
    <div class="d-block">
      <div class="row">
        <div class="col-10">
          <h2>Kép beillesztése</h2>
          <h4>[foto,"kép neve","képaláírás","méret","igazítás"]</h4>
          <p>
            <b>kép neve:</b> a cikkíró számára szolgál azonosításképp<br>
            <b>képaláírás:</b> a kép alatt megjelenő szöveg<br>
            <b>méret:</b> a kép mérete, lehetséges értékek: kicsi (s), normál (n), nagy (b), extra (e)<br>
            <b>igazítás:</b> lehetséges értékek: balra (l), középre (m), jobbra (r)
          </p>
        </div>

        <div class="col-10">
          <h2>Fájl beillesztése</h2>
          <h4>[file,"file neve"]Ez a hivatkozás[-file]</h4>
          <p>
            <b>file neve:</b> ezen a néven fogja elmenteni a böngészö az olvasónál
          </p>
        </div>
      </div>
    </div>
    <b-button class="mt-1 float-right" @click="$bvModal.hide('bv-modal-post-help')">
      Bezárás
    </b-button>
  </b-modal>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'

export default {
  name: "postHelpModal",
  components: {
    BModal,
    BButton,
  }
}
</script>
