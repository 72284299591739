<template>
  <ckeditor id="editor" v-if="ckeditorComponent" :editor="editor" :config="editorConfig" v-model="editorValue"></ckeditor>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/hu';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    state: {
      type: Boolean|null,
      required: false
    }
  },
  created() {
    this.ckeditorComponent = false

    this.$nextTick().then(() => {
      this.ckeditorComponent = true
    })
  },
  data() {
    return {
      ckeditorComponent: true,
      editor: ClassicEditor,
      editorValue: this.value,
      editorConfig: {
        language: 'hu',
        toolbar: {
          items: [
            'undo', 'redo',
            '|', 'heading',
            '|', 'bold', 'italic',
            '|', 'link', 'blockQuote',
            '|', 'bulletedList', 'numberedList'
          ],
          shouldNotGroupWhenFull: false
        }
      }
    }
  },
  watch: {
    value: {
      handler(parentDateValue) {
        this.editorValue = parentDateValue
      },
      deep: true,
    },
    editorValue: {
      handler(val) {
        this.$emit('input', val)
      },
      deep: true,
    },
  },
}
</script>
