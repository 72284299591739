var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-title":""}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('card.title.add.post'))+" ")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('card.description.add.post'))+" ")])],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.title')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.title').toLowerCase(),"rules":"required","vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.title').toLowerCase()},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.status')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.status').toLowerCase(),"rules":"required","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.statusOptions},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.category')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.category').toLowerCase(),"rules":"required","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.categoryOptions},model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.tags')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.tags').toLowerCase(),"vid":"tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","multiple":"","push-tags":"","options":_vm.tagOptions},model:{value:(_vm.formData.tags),callback:function ($$v) {_vm.$set(_vm.formData, "tags", $$v)},expression:"formData.tags"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.lead')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.lead').toLowerCase(),"rules":"required","vid":"lead"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"rows":"10"},model:{value:(_vm.formData.lead),callback:function ($$v) {_vm.$set(_vm.formData, "lead", $$v)},expression:"formData.lead"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.postContent')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.postContent').toLowerCase(),"rules":"required","vid":"content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-link',{on:{"click":function($event){return _vm.$bvModal.show('bv-modal-post-help')}}},[_c('small',{staticClass:"text-primary cursor-pointer"},[_vm._v("* \"Speciális jelölések\" súgó")])]),_c('editor',{attrs:{"value":_vm.formData.content},on:{"input":_vm.contentUpdated}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.visibleFrom')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.visibleFrom').toLowerCase(),"rules":"","vid":"visibleFrom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.visibleFrom),callback:function ($$v) {_vm.$set(_vm.formData, "visibleFrom", $$v)},expression:"formData.visibleFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.visibleTo')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.visibleTo').toLowerCase(),"rules":"","vid":"visibleTo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.visibleTo),callback:function ($$v) {_vm.$set(_vm.formData, "visibleTo", $$v)},expression:"formData.visibleTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.sticky'),"vid":"sticky"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.sticky),callback:function ($$v) {_vm.$set(_vm.formData, "sticky", $$v)},expression:"formData.sticky"}},[_vm._v(" "+_vm._s(_vm.$t('form.sticky'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('label.button.save'))+" ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1),_c('help-modal')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }